// src/components/Login/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginUsersUrl } from '../../services/UserService';
import { useDispatch } from 'react-redux';
import { login } from '../../features/auth/authSlice';
import { notifySuccess, notifyError } from '../../services/NotificationService';
import LoadingSpinner from '../Loader/loader';
import { getStatesData, getProfileUrl, getProfileChoiceFieldsUrl } from "../../services/ProfileService";
import { setStateData, UserProfileExistOrNot, allChoicesFromBackend } from "../../features/tliservice/initialService";

const initialLoginData = {
    email: "",
    password: "",
};

function Login() {
    const [loginData, setLoginData] = useState(initialLoginData);
    const { email, password } = loginData;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setLoginData({ ...loginData, [event.target.name]: event.target.value });
    };

    const loginUser = async (event) => {
        try {
            event.preventDefault();
            if (!loginData.email || !loginData.email.includes('@') || !loginData.email.includes('.')) {
                notifyError('Please enter a valid email address.');
                return;
            }
            setLoading(true);
            await LoginUsersUrl(loginData);
            setLoading(false);
            dispatch(login());
            const fetchData = async () => {
                try {
                  const stateData = await getStatesData();
                  const profileData = await getProfileUrl();
                  const allChoices = await getProfileChoiceFieldsUrl();
                  console.log(allChoices.data)
                  dispatch(setStateData(stateData.data));
                  dispatch(UserProfileExistOrNot(profileData.data));
                  dispatch(allChoicesFromBackend(allChoices.data));
          
                } catch (err) {
                  console.error('Loading all initial data', err);
                }
              };
              
            fetchData();
            navigate('/');
            notifySuccess("Logged in successfully!");
        } catch (e) {
            if (e.response && e.response.status === 404) {
                notifyError('Email or Password is invalid.');
            } else if (e.response && e.response.status === 400) {
                notifyError('Invalid input. Please check your email and password.');
            } else {
                notifyError('Pleaser Try Again.');
            }
            setLoading(false);
        }
    };

    if (loading) {
        return <LoadingSpinner visible={true} />;
    }

    return (
        <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
            <div className="row">
                <div className="col-md-4 col-10 w-100">
                    <form onSubmit={loginUser} noValidate className="border p-4 rounded bg-light">
                        <h2 className="text-center mb-4">Login</h2>
                        <div className="mb-4">
                            <label className="form-label">Email:</label>
                            <input
                                type="email"
                                className="form-control border border-primary"
                                name="email"
                                value={email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Password:</label>
                            <input
                                type="password"
                                className="form-control border border-primary"
                                name="password"
                                value={password}
                                onChange={handleChange}
                            />
                        </div>
                        <button className='btn btn-success w-100' type="submit">Login</button>
                        <div className="mt-4 text-center">
                            <a href="/reset-password" className="text-decoration-none">forgot password</a>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
}

export default Login;
