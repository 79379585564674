// src/services/NotificationService.js
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastConfig = {
    position: "top-center",
    autoClose: 3000, // 2 seconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
};

export const notifySuccess = (message) => {
    toast.success(message, toastConfig);
};

export const notifyError = (message) => {
    toast.error(message, toastConfig);
};

// Export the ToastContainer with the configuration
export const ToastProvider = () => <ToastContainer {...toastConfig} />;
