import Home from "./Components/Home/Home";
import Navbar from './Common/Navbar';
import Footer from "./Common/Footer";
import Login from "./Components/Auth/Login";
import SignUp from "./Components/Auth/Signup";
import {ToastContainer } from 'react-toastify';
import Profile from "./Components/Home/Profile";
import SearchProfile from "./Components/Home/SearchProfile";
import { Route, Routes } from 'react-router-dom';
import SingleUserProfile from "./Components/Home/SingleUserProfile";
import Contact from "./Components/Home/Contact";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import ResetPasswordWithLink from "./Components/Auth/ResetPasswordWithLink";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<SignUp/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/searchprofile" element={<SearchProfile/>}/>
        <Route path="/profile/:id" element={<SingleUserProfile/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/change-password" element={<ForgotPassword/>} />
        <Route path="/reset-password" element={<ResetPassword/>} />
        <Route path="/api/user/reset-password/:id/:token" element={<ResetPasswordWithLink />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
