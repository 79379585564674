import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPaymentStatus } from "../../services/PaymentService";
import UserSearchProfile from "./UserSearchProfile";
import { getAllUserProfileData } from "../../services/ProfileService";
import PaymentInstructions from "./PaymentInstruction";
import { getAccessToken, getRawToken } from '../../utils/commonutils';
import { jwtDecode } from 'jwt-decode';

const SearchProfile = () => {
  // const navigate = useNavigate();
  const [PaymentStatus, setPaymentStatus] = useState(null);
  const [UserSearchProfileData, SetUserSearchProfileData] = useState([]);
  const tkn = getAccessToken();
  const rtoken = getRawToken(tkn);
  const decodedToken = jwtDecode(rtoken);
  const is_outsider = decodedToken.is_outsider;
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    id:'',
    state_name: '',
    education: '',
    marital_status: '',
    deeni_interest: '',
  });

  const states = useSelector((state) => state.indata.state_data);
  const choices = useSelector((state) => state.indata.all_choice_field_from_backend);

  useEffect(() => {
    // Only run the useEffect if the user is not an outsider
    if (is_outsider === false) {
      const fetchData = async () => {
        try {
          // Check Payment Status
          const paymentResponse = await getPaymentStatus();
          setPaymentStatus(paymentResponse.data.status);

          // Fetch User Profile Data
          const profileResponse = await getAllUserProfileData();
          SetUserSearchProfileData(profileResponse.data.profile_data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [is_outsider]); // Only re-run if is_outsider changes

      // Display message for the outsider
      if (is_outsider) {
        return (
          <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
            <div className=" text-center w-75 w-md-50 p-4 border-0 shadow-sm rounded-3">
              <h2 className="mb-4">
                You cannot search the profile, as you do not know Khurshid Imaam
              </h2>
              <button
                className="btn btn-primary"
                onClick={() => navigate('/')} // Redirects to the home page
              >
                Go to Home
              </button>
            </div>
          </div>
        );
      }

  if (PaymentStatus === null) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  // Filtering profiles based on the selected filters
  const filteredProfiles = UserSearchProfileData.filter(profile => {
    return (
      (!filters.state_name || profile.state_name === filters.state_name) &&
      (!filters.education || profile.education === filters.education) &&
      (!filters.marital_status || profile.marital_status === filters.marital_status) &&
      (!filters.deeni_interest || profile.deeni_interest.includes(filters.deeni_interest)) &&
      (!filters.partner_revert || profile.partner_revert === filters.partner_revert) && 
      (!filters.id || profile.id.toString().includes(filters.id)) // Assuming 'id' is a string
    );
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
    };

  return (
    <>
      {PaymentStatus === "approved" ? (
        <div className="container my-5">
          <h2 className="text-center mb-4  mb-2">Search Profiles</h2>
          <div className="row mb-4">
            <div className="col-12 col-md-2 mb-3">
              <select className="form-select" name="state_name" onChange={handleFilterChange}>
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.name} value={state.name}>{state.name}</option>
                ))}
              </select>
            </div>

            <div className="col-12 col-md-2 mb-3">
              <select className="form-select" name="education" onChange={handleFilterChange}>
                <option value="">Select Education</option>
                {choices.education && Object.entries(choices.education).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
              </select>
            </div>

            <div className="col-12 col-md-2 mb-3">
              <select className="form-select" name="partner_revert" onChange={handleFilterChange}>
                <option value="">Looking for revert</option>
                {choices.marital_status && Object.entries(choices.partner_revert).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
              </select>
            </div>

            <div className="col-12 col-md-2 mb-3">
              <select className="form-select" name="marital_status" onChange={handleFilterChange}>
                <option value="">Select Marital Status</option>
                {choices.marital_status && Object.entries(choices.marital_status).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
              </select>
            </div>

            <div className="col-12 col-md-2 mb-3">
              <select className="form-select" name="deeni_interest" onChange={handleFilterChange}>
                <option value="">Select Deeni Interest</option>
                {choices.deeni_interest && Object.entries(choices.deeni_interest).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
              </select>
            </div>

          {/* ID Search */}
          <div className="col-12 col-md-2 mb-3">
            <input
              type="text"
              className="form-control"
              name="id"
              placeholder="Search by ID"
              onChange={handleFilterChange}
            />
          </div>


          </div>

          <UserSearchProfile profileData={filteredProfiles} />
        </div>
      ) : PaymentStatus === "pending" ? (
        <div className="text-center mt-5">
          <h2>Your payment has been successfully submitted. Please wait for admin approval</h2>
        </div>
      ) : PaymentStatus === "success" ? (
        <div className="text-center mt-5">
          <h2>Your payment is approved. Please wait for profile approval</h2>
        </div>
      ) : (
        <PaymentInstructions />
      )}

      <style jsx="true">{`
        .container {
          padding: 15px;
          background-color: #f4f6f9;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        
        h2 {
          color: #007bff;
          font-weight: bold;
        }

        .form-select {
          background-color: #f8f9fa;
          border-radius: 5px;
          border: 1px solid #ced4da;
          transition: border-color 0.3s ease;
        }

        .form-select:focus {
          border-color: #80bdff;
          box-shadow: 0 0 0 0.25rem rgba(38, 143, 255, 0.25);
        }

        .form-select:hover {
          border-color: #007bff;
        }

        .row {
          margin-bottom: 20px;
        }

        @media (max-width: 576px) {
          .form-select {
            font-size: 14px;
            padding: 0.5rem;
          }

          h2 {
            font-size: 1.5rem;
          }

          .container {
            padding: 10px;
          }
        }

        @media (min-width: 577px) {
          .container {
            padding: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default SearchProfile;
