// ApiService.js
import { setAccessToken, setRefreshToken } from '../utils/commonutils';
import { axiosProtectedInstance, axiosNormalInstance } from './BaseService';


export const sendResetPasswordEmail = async (email) => {
    try {
      const response = await axiosNormalInstance.post('/api/user/send-reset-password-email/', { email });
      return response;
    } catch (error) {
        // If error response is available, throw specific error messages
        if (error.response.data.error_code === 3) {
            throw new Error(error.response.data.error_message);
        }
        console.log("check what is the error", error)
        throw new Error("Please Try Again After Some Time");
    }
  };



export const resetPassword = async (id, token, newPassword, confirmPassword) => {
    try {
        const response = await axiosNormalInstance.post(`/api/user/reset-password/${id}/${token}/`, {
        password: newPassword,
        password2: confirmPassword,
        });
        return response;
    } catch (error) {
        // If error response is available, throw specific error messages
        if (error.response.data.error_code === 4) {
            throw new Error(error.response.data.error_message);
        }
        console.log("check what is the error", error)
        throw new Error("Please Try Again After Some Time");    }
};


export const changePassword = async (newPassword, confirmPassword) => {
    try {
      const response = await axiosProtectedInstance.post('/api/user/changepassword/', {
        password: newPassword,
        password2: confirmPassword,
        });
      return response;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  };
  
  
  
export const LoginUsersUrl = async (LoginData) => {
    try {
        const response = await axiosNormalInstance.post('/api/user/login/', LoginData);
        setAccessToken(response.data.token.access);
        setRefreshToken(response.data.token.refresh);

    } catch (error) {
        throw error;
    }
};

export const getUserAbout = async () => {
    try {
        const response = await axiosProtectedInstance.get('/api/user/profile/');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const submitContactFormUrl = async (contactData) => {
    try {
        const response = await axiosNormalInstance.post('/api/user/submit-contact/', contactData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const registerUserUrl = async (SignUpData) => {
    try {
        const response = await axiosNormalInstance.post('/api/user/register/', SignUpData);
        setAccessToken(response.data.token.access);
        setRefreshToken(response.data.token.refresh);
        return response.data;
    } catch (error) {
        console.log("full error", error, "this is only response form backend", error.response, "this is the stuts of the response", error.response.status)

        // If error response is available, throw specific error messages
        if (error.response.data.error_code === 0) {
            throw new Error(error.response.data.error_message
                || 'Registration failed. Please try again.');
        }
        // Handle network errors or other errors
        throw new Error('Network error. Please check your connection and try again.');
    }
};
