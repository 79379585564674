// src/utils/formUtils.js

export const validateRequiredFields = (formData, requiredFields) => {
    return requiredFields.filter((field) => !formData[field]);
  };
  
export const getTodayDate = () => {
    return new Date().toISOString().split("T")[0];
  };




// Utility function to validate if age is 18 or older
export const ageShouldGreaterThan18 = (dateOfBirth) => {


  const dob = new Date(dateOfBirth);
  const age = new Date().getFullYear() - dob.getFullYear();
  const m = new Date().getMonth() - dob.getMonth();

  // If the person is less than 18 years old, return an error message
  if (age < 18 || (age === 18 && m < 0)) {
    return "You must be at least 18 years old.";
  }

  return null; // No error if age is 18 or older
};

  

