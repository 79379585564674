import React from "react";

const Step1 = ({ formData, handleChange, handleNext, today, choices }) => {
  // Helper function to render select fields or checkboxes
  const renderSelectField = (label, name, options, isMultiSelect = false) => (
    <div className="form-group mb-3">
      <label>{label}</label>
      {isMultiSelect ? (
        <div>
          {options &&
            Object.entries(options).map(([key, value]) => (
              <div key={key} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`${name}_${key}`}
                  name={name}
                  value={key}
                  checked={formData[name]?.includes(key)} // Check if the value is in the array
                  onChange={(e) => handleChange(e, true)} // Handle multi-select as checkbox
                />
                <label className="form-check-label" htmlFor={`${name}_${key}`}>
                  {value}
                </label>
              </div>
            ))}
        </div>
      ) : (
        <select
          className="form-control"
          name={name}
          value={formData[name]}
          onChange={(e) => handleChange(e)}
          required
        >
          <option value="">Select {label}</option> {/* Placeholder */}
          {options &&
            Object.entries(options).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
        </select>
      )}
    </div>
  );

  return (
    <div className="bg-light p-4 rounded shadow" encType="multipart/form-data">
      <div className="form-group mb-3">
        <label htmlFor="mother_name">Candidate Full Name</label>
        <input
          type="text"
          className="form-control"
          name="full_name"
          placeholder="For whom profile is being created"
          value={formData.full_name}
          onChange={handleChange}
          required
        />
      </div>

      {renderSelectField(
        "Native State Language",
        "native_state_language",
        choices.native_state_language
      )}
      {renderSelectField(
        "Resident State Language",
        "resident_state_language",
        choices.resident_state_language
      )}
      <div className="form-group mb-3">
        <label htmlFor="phone_number">Phone Number</label>
        <input
          type="tel"
          className="form-control"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="date_of_birth">Date of Birth</label>
        <input
          type="date"
          className="form-control"
          name="date_of_birth"
          value={formData.date_of_birth}
          onChange={handleChange}
          max={today}
          required
        />
      </div>
      {renderSelectField("Gender", "gender", choices.gender)}
      {renderSelectField("Deeni Interest", "deeni_interest", choices.deeni_interest, true)}
      {renderSelectField("Are you revert", "revert", choices.revert)}
      <button type="button" className="btn btn-primary" onClick={handleNext}>
        Next
      </button>
    </div>
  );
};

export default Step1;
