import React, { useState } from "react";
import { uploadPaymentProof } from "../../services/PaymentService";
import { notifySuccess, notifyError } from "../../services/NotificationService";
import { useNavigate } from "react-router-dom";
const PaymentInstructions = () => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();


  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Store the selected file in state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      notifyError("Please select a file before submitting.");
      return;
    }

    try {
      const response = await uploadPaymentProof(file); // Call the API function
      notifySuccess("Payment proof uploaded successfully!");
      console.log("API Response:", response); // Optional: Log the response
      navigate("/")
    } catch (error) {
      console.error("Error uploading payment proof:", error);
      notifyError("Failed to upload payment proof. Please try again.");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="card shadow-lg p-4" style={{ maxWidth: "500px" }}>
        <div className="card-body text-center">
          <h2 className="card-title text-success mb-3">Complete Your Payment</h2>
          <p className="card-text text-muted">
            We are currently working on integrating a secure payment gateway. 
            Until then, please use the following details to complete your payment:
          </p>
          <p className="card-text mt-3">
            <strong>Amount:</strong>{" "}
            <span className="text-primary fw-bold">₹500</span>
          </p>
          <p className="card-text mt-3">
            <strong>Pay to:</strong>{" "}
            <span className="text-success fw-bold">+91-9876543210</span>
          </p>
          <p className="card-text text-warning">
            <i className="fas fa-info-circle me-2"></i>
            Upload your payment screenshot to confirm the transaction.
          </p>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-3">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="form-control"
                required
              />
            </div>
            <button type="submit" className="btn btn-success w-100">
              Upload Screenshot
            </button>
          </form>
          <div className="mt-3 text-muted small">
            <i className="fas fa-lock me-2"></i>Your information is secure with us.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInstructions;
