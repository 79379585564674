import React from "react";

const ProfileCard = ({ imageUrl, altText, onClick }) => {
  return (
    <div
      className="text-center position-relative psize" // Apply psize class here
      style={{
        backgroundColor: "#6CB3E1", // Light contrasting background
        padding: "5px", // Spacing inside the container
        display: "flex", // Center the content
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)", // Background container shadow
        position: "relative", // To layer text and image
        // animation: "backgroundAnimation 3s ease infinite", // Animation for background color
        borderRadius: "50%", // Make the box round
        marginTop:"20px",
      }}
    >
      {/* Internal CSS for background animation */}
      <style>
        {`
          @keyframes backgroundAnimation {
            0% {
              background-color: rgba(255, 99, 132, 0.5);
            }
            50% {
              background-color: rgba(54, 162, 235, 0.5);
            }
            100% {
              background-color: rgba(255, 159, 64, 0.5);
            }
          }

          /* Media Queries for responsiveness */
          /* Default size for desktop */
          .psize {
            width: 300px;
            height: 300px;
            border-radius: 50%; /* Ensures the container is round */
          }

          /* For tablets, adjust size */
          @media (max-width: 1024px) {
            .psize {
              width: 250px;
              height: 250px;
            }
            .psize img {
              width: 100%; /* Adjust the image size for tablets */
            }
          }

          /* For mobile devices */
          @media (max-width: 600px) {
            .psize {
              width: 170px;
              height: 170px;
            }
            .psize img {
              width: 100%; /* Adjust the image size for mobile */
            }
          }
        `}
      </style>

      {/* Profile Image */}
      <img
        src={imageUrl}
        alt={altText}
        className="img-fluid rounded-circle"
        style={{
          maxWidth: "100%", // Fit within the container
          height: "auto", // Maintain aspect ratio
          borderColor: "#2980b9", // Match theme for the border
          borderWidth: "8px", // Thicker border
          borderStyle: "solid",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.3)", // Image-specific shadow
          transform: "scale(1.02)", // Slightly zoomed in by default
          transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover
        }}
        onClick={onClick} // onClick handler remains for handling any click action
        onMouseOver={(e) => {
          e.currentTarget.style.transform = "scale(1.6)"; // Zoom-in effect
          e.currentTarget.style.boxShadow = "0px 15px 40px rgba(0, 0, 0, 0.4)"; // Enhanced shadow on hover
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.transform = "scale(1.02)"; // Reset zoom
          e.currentTarget.style.boxShadow = "0px 10px 20px rgba(0, 0, 0, 0.3)"; // Reset shadow
        }}
      />
    </div>
  );
};

export default ProfileCard;
