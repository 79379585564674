import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { calculateAge } from "../../utils/helper_function";

const UserSearchProfile = ({ profileData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const navigate = useNavigate();

  const handleShowModal = (profile) => {
    setSelectedProfile(profile);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProfile(null);
  };

  const handleViewProfile = (profile) => {
    navigate(`/profile/${profile.id}`);
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">User Profiles</h2>

      {profileData.length > 0 ? (
        <div className="row">
          {profileData.map((profile, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6 mb-3" key={index}>
              <div className="card text-center shadow-sm border-light p-2">
                <img
                  src={profile.photo1 || "default-avatar.png"}
                  className="card-img-top rounded-circle mx-auto cursor-pointer"
                  alt="Profile"
                  style={{ width: "100px", height: "100px" }}
                  onClick={() => handleShowModal(profile)}
                />
                <div className="card-body p-1 mt-2">
                  <p className="card-title">
                    Id: {profile.id}
                  </p>
                  <h5 className="card-title">
                    {profile.full_name}
                  </h5>
                  <p className="text-muted mb-1 small">
                    <strong>State:</strong> {profile.state_name}
                  </p>
                  <p className="text-muted mb-1 small">
                  <strong>Age:</strong> {calculateAge(profile.date_of_birth)}
                  </p>
                  <p className="text-muted mb-1 small">
                    <strong>Profession:</strong> {profile.profession}
                  </p>
                  <p className="text-muted mb-1 small">
                    <strong>Interest:</strong> {profile.deeni_interest.join(", ")}
                  </p>
                </div>
                <div className="card-footer p-1">
                  <button
                    className="btn btn-primary w-100 btn-sm"
                    onClick={() => handleViewProfile(profile)}
                  >
                    View Profile
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">
          <p>No profiles found matching your search criteria.</p>
        </div>
      )}

      {/* Modal for Image Carousel */}
      {selectedProfile && (
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showModal}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {selectedProfile.first_name} {selectedProfile.last_name}'s Photos
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleCloseModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src={selectedProfile.photo1 || "default-avatar.png"}
                        className="d-block w-100"
                        alt="your first profile"
                      />
                    </div>
                    {selectedProfile.photo2 && (
                      <div className="carousel-item">
                        <img
                          src={selectedProfile.photo2}
                          className="d-block w-100"
                          alt="your second profile"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSearchProfile;
