import React from 'react'


const mystyle={
    padding:"0px",
    margin:"0px",
    textAlign:"center",
    color:"blue"
}

function Linebar() {
  return (
    <>
      <div className="row">
        <div className="col-md-12 mt-5 pt-3"  >
            <div className=' pt-3 mb-0 ' style={mystyle}  >
              <p className='mb-0'>______________</p>
            </div>
        </div>
      </div>
    </>
  )
}

export default Linebar