import React from "react";

const Step3 = ({ formData, handleChange, handleNext, handleBack }) => {
  return (
    <form className="bg-light p-4 rounded shadow" encType="multipart/form-data">

      {/* Father's Information */}
      <div className="form-group mb-3">
        <label htmlFor="father_name">Father's Name</label>
        <input
          type="text"
          className="form-control"
          name="father_name"
          value={formData.father_name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="father_occupation">Father's Occupation</label>
        <input
          type="text"
          className="form-control"
          name="father_occupation"
          value={formData.father_occupation}
          onChange={handleChange}
          required
        />
      </div>

      {/* Mother's Information */}
      <div className="form-group mb-3">
        <label htmlFor="mother_name">Mother's Name</label>
        <input
          type="text"
          className="form-control"
          name="mother_name"
          value={formData.mother_name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="mother_occupation">Mother's Occupation</label>
        <input
          type="text"
          className="form-control"
          name="mother_occupation"
          value={formData.mother_occupation}
          onChange={handleChange}
          required
        />
      </div>


      {/* Height and Weight */}
      <div className="form-group mb-3">
        <label htmlFor="height">Height (cm)</label>
        <select
          className="form-control"
          name="height"
          value={formData.height}
          onChange={handleChange}
          required
        >
          <option value="">Select Height</option>
          {Array.from({ length: 121 }, (_, i) => i + 100).map((height) => (
            <option key={height} value={height}>
              {height}
            </option>
          ))}
        </select>
      </div>

      {/* Weight */}
      <div className="form-group mb-3">
        <label htmlFor="weight">Weight (kg)</label>
        <select
          className="form-control"
          name="weight"
          value={formData.weight}
          onChange={handleChange}
          required
        >
          <option value="">Select Weight</option>
          {Array.from({ length: 101 }, (_, i) => i + 30).map((weight) => (
            <option key={weight} value={weight}>
              {weight}
            </option>
          ))}
        </select>
      </div>
      
      {/* Navigation Buttons */}
      <div className="d-flex justify-content-between">
        <button type="button" className="btn btn-secondary" onClick={handleBack}>
          Back
        </button>
        <button type="button" className="btn btn-primary mx-4" onClick={handleNext}>
          Next
        </button>
      </div>
    </form>
  );
};

export default Step3;
