// Function to truncate text to a specific number of words
export const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };


  export const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return "Not Provided"; // Handle missing data
    
    const dob = new Date(dateOfBirth);
    const today = new Date();
    
    let years = today.getFullYear() - dob.getFullYear();
    let months = today.getMonth() - dob.getMonth();
    
    // Adjust if the birthday month hasn't been reached yet
    if (months < 0 || (months === 0 && today.getDate() < dob.getDate())) {
      years -= 1;
      months += 12; // Add 12 months to compensate
    }
    
    // Calculate days for better precision (optional)
    // const daysInLastMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    // const days = today.getDate() >= dob.getDate() 
    //   ? today.getDate() - dob.getDate() 
    //   : daysInLastMonth - (dob.getDate() - today.getDate());
    
    return `${years} years ${months} months`;
  };
  