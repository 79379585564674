import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { resetPassword } from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import { notifySuccess, notifyError } from '../../services/NotificationService';

const ResetPasswordWithLink = () => {
  const { id, token } = useParams(); // Extract id and token from the URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      notifyError('Passwords do not match!');
      return;
    }

    try {
      await resetPassword(id, token, newPassword, confirmPassword)
      notifySuccess('Your password has been reset successfully login now!');
      navigate('/login');
    } catch (err) {
      notifyError(err.message);
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow-lg p-4" style={{ width: '100%', maxWidth: '400px' }}>
        <h2 className="text-center mb-4">Reset Password</h2>

        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="newPassword" className="form-label">New Password</label>
            <input
              type="password"
              id="newPassword"
              className="form-control"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
              required
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary w-100">Reset Password</button>
        </form>

        <div className="mt-4 text-center">
          <a href="/login" className="text-decoration-none">Back to Login</a>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordWithLink;
