// ApiService.js
import {axiosProtectedInstance } from './BaseService';


export const getPaymentStatus = async () => {
    try {
      const response = await axiosProtectedInstance.get('/api/payment/status/');
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };


  export const uploadPaymentProof = async (file) => {
    try {
      const formData = new FormData();
      formData.append('payment_screenshot', file); // Add the file to the FormData object
      formData.append('amount', 500); // Add the amount field

      const response = await axiosProtectedInstance.post(
        '/api/payment/upload_payment_proof/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Indicate multipart data
          },
        }
      );
  
      return response.data; // Return the response data
    } catch (error) {
      throw error; // Rethrow the error for handling
    }
  };
  
  

  export const getProfileUrl = async () => {
    try {
      const response = await axiosProtectedInstance.get('/api/profile/exists/')
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };
  