import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Linebar from '../../SmallComponent/Linebar';
import { notifySuccess, notifyError } from '../../services/NotificationService'; // Import notification service
import { submitContactFormUrl } from '../../services/UserService';
// Initial state object
const initialContactData = {
    name: "",
    email_address: "",
    enquiry: "",
};

function Contact() {    
    const [contactData, setContactData] = useState(initialContactData);
    const { name, email_address, enquiry } = contactData;
    const navigate = useNavigate();

    const handleChange = (event) => {
        setContactData({ ...contactData, [event.target.name]: event.target.value });
    };

    const submitContactForm = async (event) => {
        try {
            event.preventDefault();
            // Check if the email is valid
            if (!contactData.email_address || !contactData.email_address.includes('@') || !contactData.email_address.includes('.')) {
                notifyError('Please enter a valid email address.');
                return;
            }
            console.log(contactData)
            await submitContactFormUrl(contactData);
            notifySuccess("Form submitted successfully!");
            setContactData(initialContactData); // Reset the form after successful submission
            navigate('/');
        } catch (e) {
            notifyError("There is some server error");
        }
    };

    return (
        <>
            <div className="container-fluid text-dark">
                <Linebar />
                <div className="row mb-4">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 text-center mt-2">
                                <h5>Please reach out to us for any issue?</h5>
                            </div>
                        </div>
                        <form onSubmit={submitContactForm} noValidate>
                            <div className="row">
                                <div className="col-md-6 m-auto">
                                    <div className="mb-3">
                                        <label className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="border border-primary form-control"
                                            name="name"
                                            value={name}
                                            onChange={handleChange}
                                            placeholder="Your Name here"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Email address</label>
                                        <input
                                            type="email"
                                            className="border border-primary form-control"
                                            name="email_address"
                                            value={email_address}
                                            onChange={handleChange}
                                            placeholder="Enter your email here"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Enquiry</label>
                                        <textarea
                                            className="form-control border border-primary"
                                            name="enquiry"
                                            value={enquiry}
                                            onChange={handleChange}
                                            placeholder="Write down your query here"
                                            rows="3"
                                        ></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-success mb-3">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
