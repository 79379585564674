
export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
}

export const setAccessToken = (accessToken) => {
    localStorage.setItem('accessToken', `Bearer ${accessToken}`);
}


export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
}

export const setRefreshToken = (accessToken) => {
    localStorage.setItem('refreshToken', `${accessToken}`);
}


export const clearTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

}

/**
 * Removes the 'Bearer ' prefix from the token and returns the raw token.
 * @param {string} tokenWithBearer - The token string with the 'Bearer ' prefix.
 * @returns {string|null} The raw token without the 'Bearer ' prefix, or null if the input is invalid.
 */
export const getRawToken = (tokenWithBearer) => {
    if (typeof tokenWithBearer !== "string") {
      console.error("Invalid token format. Expected a string.");
      return null;
    }
  
    return tokenWithBearer.startsWith("Bearer ")
      ? tokenWithBearer.replace("Bearer ", "")
      : tokenWithBearer;
  };