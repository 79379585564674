import React, { useState } from 'react';
import { sendResetPasswordEmail } from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import { notifySuccess, notifyError } from '../../services/NotificationService';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Replace with your API call
      await sendResetPasswordEmail(email)
      notifySuccess('Password reset link has been sent to your email.');
      navigate("/");
    } catch (e) {
      notifyError(e.message || 'Failed to send reset email. Please try again.');
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow-lg p-4" style={{ width: '100%', maxWidth: '400px' }}>
        <h2 className="text-center mb-4">Forgot Password</h2>

        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="email" className="form-label">Email Address</label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <button type="submit" className="btn btn-success w-100">Send Reset Link</button>
        </form>

        <div className="mt-4 text-center">
          <a href="/login" className="text-decoration-none">Back to Login</a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
